export const buildingTypes = [
  { id: 1, name: "居民区" },
  { id: 2, name: "公共机构" },
  { id: 3, name: "企事业单位" },
  { id: 4, name: "写字楼" },
  { id: 5, name: "工业园区" },
  { id: 6, name: "交通枢纽" },
  { id: 7, name: "大型文体设施" },
  { id: 8, name: "城市绿地" },
  { id: 9, name: "大型建筑配建停车场" },
  { id: 10, name: "路边停车位" },
  { id: 11, name: "城际高速服务区" },
  { id: 12, name: "景区" },
  { id: 13, name: "公交场站" },
  { id: 255, name: "其他" },
];

export const buildingTypeColumn = [
  {
    defaultIndex: 0,
    values: [
      "居民区",
      "公共机构",
      "企事业单位",
      "写字楼",
      "工业园区",
      "交通枢纽",
      "大型文体设施",
      "城市绿地",
      "大型建筑配建停车场",
      "路边停车位",
      "城际高速服务区",
      "景区",
      "公交场站",
      "其他",
    ],
  },
];

export const stationTypes = [
  { id: 1, name: "公共" },
  { id: 50, name: "个人" },
  { id: 100, name: "公交(专用)" },
  { id: 101, name: "环卫(专用)" },
  { id: 102, name: "物流(专用)" },
  { id: 103, name: "出租车(专用)" },
  { id: 104, name: "分时租赁(专用)" },
  { id: 105, name: "小区共享(专用)" },
  { id: 106, name: "单位(专用)" },
  { id: 255, name: "其他" },
];

export const stationTypeColumn = [
  {
    defaultIndex: 0,
    values: [
      "公共",
      "个人",
      "公交(专用)",
      "环卫(专用)",
      "物流(专用)",
      "出租车(专用)",
      "分时租赁(专用)",
      "小区共享(专用)",
      "单位(专用)",
      "其他",
    ],
  },
];

export const stationStatus = [
  { id: 1, name: "建设中" },
  { id: 6, name: "维护中" },
  { id: 50, name: "开放运营" },
  { id: 5, name: "关闭下线" },
];

export const stationStatusColumn = [
  {
    defaultIndex: 0,
    values: [
      "建设中",
      "维护中",
      "开放运营", // 正常使用
      "关闭下线",
    ],
  },
];
